<template>
  <div id="app" style="height: 100vh">
    <el-scrollbar
      ref="scrollbar"
      wrap-class="scrollbar-wrapper"
      class="scrollbarHidden"
      style="height: 100%"
    >
      <!-- header -->
      <HeaderView class="header-app" v-if="isShow" :tap="tap"></HeaderView>
      <!-- 路由 -->
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
      <!-- 返回顶部 -->
      <div
        :class="$store.state.isPhone ? 'back-top-phone' : 'back-top'"
        @click="backTop"
      >
        <i class="el-icon-caret-top" v-show="!$store.state.isPhone"></i>
        <img
          src="@/assets/phone/back-top.png"
          alt=""
          v-show="$store.state.isPhone"
        />
      </div>
    </el-scrollbar>
    <!-- 自定义弹窗 -->
    <popupComponent ref="popupComponent" />
    <div id="petalsContainer"></div>
  </div>
</template>

<script>
import HeaderView from "@/components/cp-header/index.vue";
import popupComponent from "@/components/popupComponent/index.vue";
import { queryPlatformPath } from "@/api/home";
export default {
  components: {
    HeaderView,
    popupComponent,
  },
  data() {
    return {
      isShow: true,
      tap: "one",
      name: "",
    };
  },
  async created() {
    //是否是手机端
    this.$store.state.isPhone = /mobile/i.test(navigator.userAgent);
    // 网站初始信息
    await queryPlatformPath().then((res) => {
      //网站初始信息
      this.$store.dispatch("setProjectInfoServe", res);
      //打开弹窗
      if (!this.$store.state.isPhone) {
        if (
          !localStorage.getItem("popupIshow") ||
          this.isPastTimeOver24Hours(localStorage.getItem("popupIshow"))
        ) {
          setTimeout(() => {
            this.$refs.popupComponent.dark = true;
          }, 1000);
          localStorage.setItem("popupIshow", new Date().getTime());
        }
      }
    });
    // 监听滚动条到底
    this.handleScroll();
    // setInterval(this.createPetal,500)
  },
  watch: {
    $route(to, from) {
      this.name = to.name;
      // this.tap = to.meta.tap;
      this.isShow = to.meta.isShow;
    },
  },
  methods: {
    //判断是否过去24小时
    isPastTimeOver24Hours(pastTimeString) {
      const pastTime = new Date(Number(pastTimeString));
      const now = new Date();
      const timeDifference = now.getTime() - pastTime.getTime();
      return timeDifference >= 86400000;
    },
    //监听滚动条到底
    handleScroll() {
      this.$refs.scrollbar.handleScroll = () => {
        var wrap = this.$refs.scrollbar.wrap;
        this.$refs.scrollbar.moveY = (wrap.scrollTop * 100) / wrap.clientHeight;
        this.$refs.scrollbar.moveX = (wrap.scrollLeft * 100) / wrap.clientWidth;
        let poor = wrap.scrollHeight - wrap.clientHeight;
        if (
          poor == parseInt(wrap.scrollTop) ||
          poor == Math.ceil(wrap.scrollTop) ||
          poor == Math.floor(wrap.scrollTop)
        ) {
          this.$store.state.scrollbarLoading = true;
        }
      };
    },
    // createPetal(){
    //   const petalsContainer = document.getElementById('petalsContainer')
    //   const petal = document.createElement('div');
    //   petal.className = 'flower-petal';
    //
    //   petal.style.left = Math.random() * window.innerWidth + 'px';
    //   petal.style.top = '0px';
    //   petalsContainer.appendChild(petal);
    //
    //   this.dropPetal(petal)
    //
    // },
    // dropPetal(petal){
    //   const fallTime = Math.random() * 3 + 2;
    //   petal.style.transition = `transform${fallTime}s linear,opacity${fallTime}s linear`;
    //
    //   requestAnimationFrame(()=>{
    //     petal.style.transform = `translateY(${window.innerHeight}px)`;
    //     petal.style.opacity = '0';
    //   })
    //
    //   petal.addEventListener('transitionend',()=>{
    //     petal.remove();
    //   })
    // },
    //滚动条到顶部
    backTop() {
      this.$refs.scrollbar.wrap.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 500px) {
  .header-app {
    display: none;
  }
}
::v-deep .el-scrollbar {
  .is-horizontal {
    display: none;
  }
  /* element滚动条组件 隐藏水平滚动条 */
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
.el-form {
  height: auto !important;
}
.back-top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 60px;
  right: 20px;
  width: 50px;
  height: 50px;
  z-index: 999;
  background: #fff;
  border-radius: 100%;
  color: #007fff;
  font-size: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.back-top-phone {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 24vw;
  right: 2.66vw;
  width: 10vw;
  height: 10vw;
  z-index: 999;
  // background: #fff;
  border-radius: 100%;
  color: #007fff;
  // font-size: 6vw;
  // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
@import "@/assets/css/global.css"; // 引入全局CSS文件
</style>
